/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react'
import { convertKeys, createQuery } from 'utils/function'
import LoadingScreen from 'components/LoadingScreen'
import { ISidebarComponent } from 'components/Sidebar'
import { useNavigate } from 'react-router-dom'
import ProductSkeleton from '../../components/ProductSkeleton'
import apis from '../../apis'
import { IPagination } from 'data/types'
import { useDispatch } from 'react-redux'
import { useAdminContext } from 'context/adminContext'
import MyVotesCard from './ProductCard'
import moment from 'moment'
import Modal from 'components/CustomModal'
import CustomButton from 'app/ClipPage/CustomButton'
import { setOpenModalLogin } from 'features/authenticate/loginSlice'
import StickySidebar from 'components/StickySidebar'
import useUserLogin from 'hooks/useUserLogin'
import useInView from 'hooks/useInView'
import useMediaQuery from 'hooks/useMediaQuery'

export type TStatusDiscountCode = 'ACTIVE' | 'EXPIRED' | 'LIMIT' | ''

interface IProductsState {
  id: number
  attributes: {
    date: string
    productName: string
    productId: number
    discountId: number
  }
  productInfo: {
    id: number
    slug: string
    votes: number
    productType: string
    hoverVideo: {
      name: string
      url: string
    } | null
    thumbnail: {
      name: string
      url: string
    } | null
  }
  discountInfo: {
    id: number
    summary: string
  }
}

const MyVotes: React.FC = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [sidebar, setSidebar] = useState<ISidebarComponent[]>([])
  const [myVotes, setMyVotes] = useState({
    list: [],
    loading: false,
  })
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    pageCount: 0,
    pageSize: 10,
    total: 0,
    hasNextPage: true,
  })
  const { showAlert } = useAdminContext()
  const [open, setOpen] = useState(false)
  const [votedId, setVotedId] = useState(0)
  const [disabled, setDisabled] = useState(false)
  const { isInView, ref } = useInView()
  const { token } = useUserLogin()
  const dispatch = useDispatch()
  const mediaQuery = useMediaQuery()

  const getMyVotes = (pageNumber?: number) => {
    setMyVotes((preState) => ({ ...preState, loading: true }))
    apis
      .get(
        `my-votes`,
        createQuery({
          pagination: {
            page: pageNumber || pagination.page,
            pageSize: pagination.pageSize,
          },
        })
      )
      .then((res: any) => {
        const {
          data,
          meta: {
            pagination: { page, pageSize, total, pageCount },
          },
        } = res.data
        const list = convertKeys(data)
        const listBrandProd = list.reduce(
          (acc: IProductsState[], item: IProductsState) => {
            if (item.productInfo.productType !== 'Single') {
              acc.push({
                ...item,
              })
            }

            return acc
          },
          []
        )
        setMyVotes((preState) => ({
          ...preState,
          list: page === 1 ? listBrandProd : myVotes.list.concat(listBrandProd),
          loading: false,
        }))
        setPagination((preState) => ({
          ...preState,
          page: page + 1,
          pageSize,
          total,
          pageCount,
          hasNextPage: page !== pageCount,
        }))
        // }
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setMyVotes((preState) => ({ ...preState, loading: false }))
        setIsLoading(false)
      })
  }

  const handleGetData = () => {
    apis
      .get(
        `home-page`,
        createQuery({
          populate: {
            top_banner: {
              populate: {
                content: {
                  populate: {
                    image: {
                      fields: ['name', 'url'],
                    },
                  },
                },
                options: '*',
              },
            },
            sidebar: {
              populate: {
                content: {
                  populate: {
                    image: {
                      fields: ['name', 'url'],
                    },
                    tags: '*',
                  },
                },
                options: '*',
              },
            },
          },
        })
      )
      .then((res) => {
        const { attributes } = res.data.data
        const { sidebar } = attributes
        setSidebar(sidebar)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  const handleUnVote = (id: number) => {
    setDisabled(true)
    apis
      .update('wl-votes', id, {
        data: {
          active: 0,
        },
      })
      .then((res) => {
        showAlert('success', 'Unvote Successfully!')
        const idx = myVotes.list.findIndex((item: any) => item.id === id)
        let updateMyVote = [...myVotes.list]
        updateMyVote.splice(idx, 1)
        setMyVotes((preState) => ({
          ...preState,
          list: updateMyVote,
        }))
        setOpen(false)
        setDisabled(false)
      })
      .catch((err) => {
        setDisabled(false)
        showAlert('error', 'Unvote Failed! Please try again later!')
      })
  }

  const handleOpenModal = (voteId: number) => {
    setOpen(true)
    setVotedId(voteId)
  }

  useEffect(() => {
    localStorage.setItem('PROD_TYPE', 'Merchant')
    document.title = 'ET Wishlist | My Votes'
    handleGetData()
  }, [])

  useEffect(() => {
    if (token) {
      getMyVotes(1)
    } else {
      dispatch(setOpenModalLogin(true))
      showAlert('warning', 'This feature requires logging in to use')
      navigate('/')
    }
  }, [token])

  useEffect(() => {
    if (isInView) {
      getMyVotes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView])

  if (isLoading) return <LoadingScreen />

  return (
    <div>
      <div
        className={` bg-white ${
          mediaQuery > 1060 ? 'brands-container' : 'brands-container-sm'
        } `}
      >
        <div className="flex flex-col w-full max-w-[1060px]">
          <div className="flex justify-between w-full">
            <div className="flex flex-1 flex-col space-y-6 mt-[32px] w-full min-[852px]:pr-5 pr-0">
              <div className="flex flex-col space-y-0.5">
                <div className="product-title-22 text-[--gray-text]">
                  My Votes
                </div>
                <div className="font-[RobotoRegular] text-[12px] leading-[12px] ">
                  Vote History
                </div>
              </div>
              <div
                id="productWrapper"
                className="min-w-[135px] lg:max-w-[700px] max-w-full w-full flex flex-col space-y-[14px] mb-[24px]"
              >
                {myVotes.list.map((_i: IProductsState, idx: number) => {
                  return (
                    <Fragment key={_i.id}>
                      {myVotes.list.length === idx + 1 &&
                        pagination.hasNextPage && (
                          <div className="w-full h-1" ref={ref}></div>
                        )}
                      <MyVotesCard
                        disabled={disabled}
                        id={_i.id}
                        key={_i.id}
                        thumbnail={_i.productInfo.thumbnail?.url || ''}
                        hoverVideo={_i.productInfo.hoverVideo?.url || ''}
                        name={_i.attributes?.productName}
                        slug={`/brands-products/${_i.productInfo.slug}`}
                        countVoted={_i.productInfo.votes}
                        dateVoted={moment(_i.attributes.date).format(
                          'DD/MM/YY'
                        )}
                        summary={_i.discountInfo.summary || ''}
                        onUnVote={() => handleOpenModal(_i.id)}
                      />
                    </Fragment>
                  )
                })}
                {myVotes.loading && <ProductSkeleton />}
              </div>
            </div>
            <div className="min-[852px]:flex hidden lg:max-w-[360px] max-w-[260px] w-full">
              <StickySidebar sidebar={sidebar} product={myVotes} />
            </div>
          </div>
        </div>
      </div>
      {open && (
        <Modal onClose={() => setOpen(false)} className="!p-0">
          <div className="el-message-box confirm-dialog" tabIndex={-1}>
            <div className="el-message-box__header">
              <div className="el-message-box__title">
                <span>Are you sure you want to unvote?</span>
              </div>
            </div>
            <div id="el-id-1379-4" className="el-message-box__content">
              <div className="el-message-box__container">
                <div className="el-message-box__message">
                  <p>
                    Please note that unvoting this product will remove the
                    discount code and product from your ‘My Votes’ page.
                  </p>
                </div>
              </div>
              <div
                className="el-message-box__input"
                style={{
                  display: 'none',
                }}
              >
                <div className="el-input">
                  <div className="el-input__wrapper" tabIndex={-1}>
                    <input
                      className="el-input__inner"
                      aria-invalid="false"
                      type="text"
                      autoComplete="off"
                      tabIndex={0}
                      placeholder=""
                      id="el-id-1379-5"
                    />
                  </div>
                </div>
                <div
                  className="el-message-box__errormsg"
                  style={{ visibility: 'hidden' }}
                ></div>
              </div>
            </div>
            <div className="flex items-center mt-6 space-x-3">
              <CustomButton
                label={'Unvote'}
                onClick={() => handleUnVote(votedId)}
              />
              <CustomButton
                label={'Dismiss'}
                type="normal"
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default MyVotes
