import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { convertKeys, createQuery } from 'utils/function'
import axios from 'axios'
import { ISidebarComponent } from 'components/Sidebar'
import { IProductTags } from 'data/wl-types'
import apis from 'apis'
import ProductSkeleton from 'components/ProductSkeleton'
import { IPagination } from 'data/types'
import { NotFoundProduct1 } from 'components/NotFoundProduct'
import LoadingScreen from 'components/LoadingScreen'
import ProductCurationCard from 'components/ProductCurations/ProductCurationsCard'
import StickySidebar from 'components/StickySidebar'
import useUserLogin from 'hooks/useUserLogin'
import ProductCard from 'components/ProductCard'
import useInView from 'hooks/useInView'
import CurationSkeleton from 'components/CurationSkeleton'
import CurationTimeFilter from 'components/CurationTimeFilter'

const PageTag = () => {
  const { slug } = useParams()
  const navigate = useNavigate()
  const prodType = localStorage.getItem('PROD_TYPE') || 'Single'
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [sidebar, setSidebar] = useState<ISidebarComponent[]>([])
  const [timeFilter, setTimeFilter] = useState('most_voted')
  const [product, setProduct] = useState({
    list: [],
    loading: false,
  })
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    pageCount: 0,
    pageSize: 10,
    total: 0,
    hasNextPage: true,
  })
  const [tag, setTag] = useState<IProductTags>({
    name: '',
    slug: '',
    desc: '',
  })
  const { isInView, ref } = useInView()
  const { token } = useUserLogin()

  const handleGetData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/home-page?${createQuery({
          populate: {
            sidebar: {
              populate: {
                content: {
                  populate: {
                    image: {
                      fields: ['name', 'url'],
                    },
                    tags: '*',
                  },
                },
                options: '*',
              },
            },
          },
        })}`
      )
      .then((res) => {
        const { attributes } = res.data.data
        const { sidebar } = attributes
        setSidebar(sidebar)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getTag = () => {
    apis
      .get(
        'wl-prod-tags',
        createQuery({
          filters: {
            slug,
          },
        })
      )
      .then((res) => {
        const { data } = res.data
        const {
          attributes: { name, desc, slug },
        } = convertKeys(data[0])
        setTag({ ...tag, name, desc, slug })
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err.message)
        setIsLoading(false)
      })
  }

  const getProduct = (pageNumber?: number) => {
    setProduct((preState) => ({ ...preState, loading: true }))
    apis
      .get(
        'wl-products',
        createQuery({
          populate: {
            shop_information: true,
            thumbnail: {
              fields: ['name', 'url'],
            },
            hover_video: {
              fields: ['name', 'url'],
            },
            videos: {
              fields: ['name', 'url', 'width', 'height'],
            },
            prod_dtl: '*',
            category: {
              fields: ['name', 'slug'],
            },
            tags: {
              fields: ['name', 'slug'],
            },
            values: {
              fields: ['name', 'slug'],
            },
            short_clips: {
              populate: {
                clip: {
                  fields: ['name', 'url'],
                },
              },
            },
            our_story: {
              populate: {
                founder_image: {
                  fields: ['name', 'url'],
                },
              },
            },
            images: {
              fields: ['name', 'url', 'width', 'height'],
            },
          },
          filters: {
            tags: {
              slug,
            },
            product_type: prodType,
            page_submitted: true,
            page_approved: true,
          },
          pagination: {
            page: pageNumber || pagination.page,
            pageSize: pagination.pageSize,
          },
          sort:
            timeFilter === 'most_voted'
              ? {
                  votes: 'desc',
                  id: 'desc',
                }
              : {
                  publishedAt: 'desc',
                  id: 'desc',
                },
        })
      )
      .then((res) => {
        const {
          data,
          meta: {
            pagination: { page, pageSize, total, pageCount },
          },
        } = res.data
        const list = convertKeys(data)
        setProduct((preState) => ({
          ...preState,
          list: page === 1 ? list : preState.list.concat(list),
          loading: false,
        }))
        setPagination((preState) => ({
          ...preState,
          page: page + 1,
          pageSize,
          total,
          pageCount,
          hasNextPage: page !== pageCount,
        }))
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setProduct((preState) => ({ ...preState, loading: false }))
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getProduct(1)
    getTag()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, token, timeFilter])

  useEffect(() => {
    document.title = 'ET Wishlist | Tag'
    handleGetData()
  }, [])

  useEffect(() => {
    if (isInView) {
      getProduct()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView])

  if (isLoading) return <LoadingScreen />

  return (
    <div
      className={` bg-white ${
        prodType === 'Single'
          ? 'lg:et-container et-container-sm'
          : 'lg:brands-container brands-container-sm'
      }`}
    >
      <div className="flex flex-col w-full">
        <div className="flex justify-between w-full max-w-[1060px]">
          <div className="flex flex-col space-y-6 mt-[20px] w-full lg:max-w-[700px] max-w-full min-[852px]:pr-5 pr-0">
            <div
              className={
                prodType === 'Single'
                  ? 'lg:brands-container brands-container-sm'
                  : ''
              }
            >
              <h2 className="mb-[9px] mt-[7px] text-xs font-bold capitalize leading-none text-gray-1150 md:mb-1 md:ml-0 md:mt-[26px] md:font-semibold">
                Tags
              </h2>
              {tag.name !== '' && (
                <h1 className="mr-1  text-2xl font-bold leading-none tracking-tight text-slate-1150 md:mb-0 md:text-xl md:font-semibold md:leading-8 md:tracking-normal">
                  {tag.name}
                </h1>
              )}
              {tag.desc && <p>{tag.desc}</p>}
              <div className="flex flex-start">
                <CurationTimeFilter
                  onChange={(val) => {
                    setTimeFilter(val)
                  }}
                  isOnlyShowIcon={false}
                />
              </div>
            </div>

            {!isLoading ? (
              <Fragment>
                {product.list.length > 0 ? (
                  <div
                    id="productWrapper"
                    className="min-w-[135px] min-[852px]:max-w-[700px] max-w-full  w-full flex flex-col space-y-[14px] mb-[26px]"
                  >
                    {product.list.map((_i: any, idx: number) => {
                      const tags = _i.attributes?.tags?.data?.reduce(
                        (acc: any[], item: any) => {
                          acc.push({
                            name: item.attributes.name,
                            slug: `/${item.attributes.slug}`,
                          })
                          return acc
                        },
                        []
                      )
                      let Component = ProductCurationCard
                      if (prodType === 'Merchant') {
                        Component = ProductCard
                      }
                      return (
                        <Fragment key={_i.id}>
                          {product.list.length === idx + 1 &&
                            pagination.hasNextPage && (
                              <div className="w-full h-1" ref={ref}></div>
                            )}
                          <Component
                            id={_i.id}
                            thumbnail={
                              _i.attributes?.thumbnail.data?.attributes.url
                            }
                            hoverVideo={
                              _i.attributes?.hoverVideo.data?.attributes.url
                            }
                            name={_i.attributes?.name}
                            headline={_i.attributes?.headline}
                            subHeadline={_i.attributes?.subHeadline}
                            voted={_i.status ? _i.status.votedId !== 0 : false}
                            votedId={_i.status?.voteId || 0}
                            tags={tags}
                            award={[]}
                            slug={`/${
                              prodType === 'Merchant'
                                ? 'brands-products'
                                : 'products'
                            }/${_i.attributes?.slug}`}
                            countVoted={_i.attributes?.votes}
                            spPrdId={_i.attributes?.shopProductId}
                            navigate={() =>
                              navigate(`/products/${_i.attributes?.slug}`)
                            }
                            bookmarked={_i.isBookmarked}
                            prdImages={
                              _i.attributes?.images?.data
                                ? _i.attributes?.images?.data
                                : []
                            }
                            prdVideo={
                              _i.attributes?.videos?.data?.length > 0
                                ? _i.attributes?.videos?.data
                                : []
                            }
                            shopVariantPrice={_i?.attributes?.shopVariantPrice}
                            shopVariantCurrency={
                              _i?.attributes?.shopVariantCurrency
                            }
                            prdDetail={_i}
                          />
                        </Fragment>
                      )
                    })}
                    {product.loading &&
                      (prodType === 'Single' ? (
                        <CurationSkeleton />
                      ) : (
                        <ProductSkeleton />
                      ))}
                  </div>
                ) : (
                  <div
                    className={
                      prodType === 'Single'
                        ? 'lg:brands-container brands-container-sm'
                        : ''
                    }
                  >
                    <NotFoundProduct1 />
                  </div>
                )}
              </Fragment>
            ) : prodType === 'Single' ? (
              <CurationSkeleton />
            ) : (
              <ProductSkeleton />
            )}
          </div>
          <div className="min-[852px]:flex hidden lg:max-w-[360px] max-w-[260px] w-full">
            <StickySidebar sidebar={sidebar} product={product} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageTag
