/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react'
import { convertKeys, createQuery, devLog } from 'utils/function'
import LoadingScreen from 'components/LoadingScreen'
import { ISidebarComponent } from 'components/Sidebar'
import { IImage } from 'data/wl-types'
import { useNavigate } from 'react-router-dom'
import apis from '../../apis'
import { IPagination } from 'data/types'
import ProductCurationCard from 'components/ProductCurations/ProductCurationsCard'
import { IProductState } from 'components/PrdDetailCommon/prdDetailInterface'
import StickySidebar from 'components/StickySidebar'
import useUserLogin from 'hooks/useUserLogin'
import useInView from 'hooks/useInView'
import { useDispatch } from 'react-redux'
import { setOpenModalLogin } from 'features/authenticate/loginSlice'
import { useAdminContext } from 'context/adminContext'
import CurationSkeleton from 'components/CurationSkeleton'

export interface ITopBannerImage {
  id: number
  link_on_click: string
  image: {
    data: IImage | null
  }
}

export interface ITopBannerText {
  button_label: string
  background: string | null
  content: string | null
  button_background: string | null
  button_color: string | null
  accept_close: boolean
  link_on_click: string
}

export interface IShowSharePrd {
  showSharePrd: boolean
  prdDetail: IProductState | null
}

const HomePageCMS: React.FC = () => {
  const [listPage, setListPage] = useState<number[]>([])
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [sidebar, setSidebar] = useState<ISidebarComponent[]>([])

  const [product, setProduct] = useState({
    list: [],
    loading: false,
  })
  const { showAlert } = useAdminContext()
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    pageCount: 0,
    pageSize: 10,
    total: 0,
    hasNextPage: true,
  })
  const { isInView, ref } = useInView()
  const { token } = useUserLogin()
  const dispatch = useDispatch()

  const getMyBookmarks = (page?: number) => {
    setProduct({ ...product, loading: true })
    apis
      .get(
        'get-my-bookmarks',
        createQuery({
          pagination: {
            page: page || pagination.page,
            pageSize: pagination.pageSize,
          },
          sort: {
            createdAt: 'desc',
          },
        })
      )
      .then((res: any) => {
        const {
          data,
          meta: {
            pagination: { page, pageSize, total, pageCount },
          },
        } = res.data
        const list = convertKeys(data)

        setProduct({
          list: page === 1 ? list : product.list.concat(list),
          loading: false,
        })

        setPagination({
          ...pagination,
          page: page + 1,
          pageSize,
          total,
          pageCount,
          hasNextPage: page !== pageCount,
        })
        setIsLoading(false)
        listPage.findIndex((item) => item === page) === -1 &&
          setListPage((preState) => [...preState, page])
      })
      .catch((err) => {
        devLog(err, 'HomePage')
        setProduct((preState) => ({ ...preState, loading: false }))
        setIsLoading(false)
      })
  }

  const getPageInfo = () => {
    apis
      .get(
        `home-page`,
        createQuery({
          populate: {
            sidebar: {
              populate: {
                content: {
                  populate: {
                    image: {
                      fields: ['name', 'url'],
                    },
                    tags: '*',
                  },
                },
                options: '*',
              },
            },
          },
        })
      )
      .then((res) => {
        const { attributes } = res.data.data
        const { sidebar } = attributes
        setSidebar(sidebar)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    localStorage.setItem('PROD_TYPE', 'Single')
    document.title = 'ET Wishlist | My Bookmarks'
    getPageInfo()
  }, [])

  useEffect(() => {
    if (token) {
      getMyBookmarks(1)
    } else {
      dispatch(setOpenModalLogin(true))
      showAlert('warning', 'This feature requires logging in to use')
      navigate('/')
    }
  }, [token])

  useEffect(() => {
    if (isInView) {
      getMyBookmarks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView])

  if (isLoading) return <LoadingScreen />

  return (
    <div>
      <div className=" bg-white lg:brands-container brands-container-sm">
        <div className="flex flex-col w-full mt-[32px]">
          <div className="flex flex-col space-x-0.5">
            <div className="product-title-22 text-[--gray-text]">
              My Bookmarks
            </div>
            <div className="text-[12px] leading-[12px]">Bookmarks History</div>
          </div>
          <div
            className={`flex ${
              sidebar.length > 0 ? 'justify-between' : 'justify-center'
            } w-full`}
          >
            <div className="flex flex-col space-x-6 mt-[20px] w-full lg:max-w-[700px] max-w-full sm:pr-5 pr-0">
              <div
                id="productWrapper"
                className="min-w-[135px] lg:max-w-[700px] max-w-full w-full flex flex-col space-x-[14px] mb-[26px]"
              >
                {product.list.map((_i: any, idx) => {
                  const tags = _i.attributes?.tags?.data?.reduce(
                    (acc: any[], item: any) => {
                      acc.push({
                        name: item.attributes.name,
                        slug: `/${item.attributes.slug}`,
                      })
                      return acc
                    },
                    []
                  )
                  return (
                    <Fragment key={_i.id}>
                      {product.list.length === idx + 1 &&
                        pagination.hasNextPage && (
                          <div className="w-full h-1" ref={ref}></div>
                        )}
                      <ProductCurationCard
                        id={_i.id}
                        thumbnail={
                          _i.attributes?.thumbnail.data?.attributes.url
                        }
                        hoverVideo={
                          _i.attributes?.hoverVideo.data?.attributes.url
                        }
                        name={_i.attributes?.name}
                        headline={_i.attributes?.headline}
                        subHeadline={_i.attributes?.subHeadline}
                        voted={_i.status ? _i.status.votedId !== 0 : false}
                        votedId={_i.status?.voteId || 0}
                        tags={tags}
                        award={[]}
                        slug={`/products/${_i.attributes?.slug}`}
                        countVoted={_i.attributes?.votes}
                        spPrdId={_i.attributes?.shopProductId}
                        navigate={() =>
                          navigate(`/products/${_i.attributes?.slug}`)
                        }
                        bookmarked={_i.isBookmarked}
                        prdImages={
                          _i.attributes?.images ? _i.attributes?.images : []
                        }
                        prdVideo={
                          _i.attributes?.videos?.length > 0
                            ? _i.attributes?.videos
                            : []
                        }
                        shopVariantPrice={_i?.attributes?.shopVariantPrice}
                        shopVariantCurrency={
                          _i?.attributes?.shopVariantCurrency
                        }
                        prdDetail={_i}
                      />
                    </Fragment>
                  )
                })}
                {product.loading && <CurationSkeleton />}
              </div>
            </div>
            <div
              className={`min-[852px]:flex hidden lg:max-w-[360px] max-w-[260px] w-full`}
            >
              <StickySidebar sidebar={sidebar} product={product} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePageCMS
