import axios, { CancelToken } from 'axios'
import moment from 'moment'
import { createQuery } from 'utils/function'

const apis = {
  get: async (url: string, query?: string) => {
    const localToken =
      localStorage.getItem('ETWL') &&
      JSON.parse(localStorage.getItem('ETWL') || '')?.token

    try {
      return await axios.get(
        `${process.env.REACT_APP_API_URL}/${url}${query ? `?${query}` : ''}`,
        {
          headers:
            localToken && localToken !== ''
              ? { Authorization: `Bearer ${localToken}` }
              : {},
        }
      )
    } catch (err: any) {
      throw err
    }
  },

  getOne: async (url: string, id: number, query?: string) => {
    const localToken =
      localStorage.getItem('ETWL') &&
      JSON.parse(localStorage.getItem('ETWL') || '')?.token
    try {
      return await axios.get(
        `${process.env.REACT_APP_API_URL}/${url}/${id}${query ? `?${query}` : ''
        }`,
        {
          headers:
            localToken && localToken !== ''
              ? { Authorization: `Bearer ${localToken}` }
              : {},
        }
      )
    } catch (err: any) {
      throw err
    }
  },

  post: async (url: string, data: any) => {
    const localToken =
      localStorage.getItem('ETWL') &&
      JSON.parse(localStorage.getItem('ETWL') || '')?.token
    try {
      return await axios.post(
        `${process.env.REACT_APP_API_URL}/${url}`,
        {
          ...data,
        },
        {
          headers:
            localToken && localToken !== ''
              ? { Authorization: `Bearer ${localToken}` }
              : {},
        }
      )
    } catch (err: any) {
      throw err
    }
  },

  update: async (url: string, id: number, data: any) => {
    const localToken =
      localStorage.getItem('ETWL') &&
      JSON.parse(localStorage.getItem('ETWL') || '')?.token
    try {
      return await axios.put(
        `${process.env.REACT_APP_API_URL}/${url}/${id}`,
        {
          ...data,
        },
        {
          headers:
            localToken && localToken !== ''
              ? { Authorization: `Bearer ${localToken}` }
              : {},
        }
      )
    } catch (err: any) {
      throw err
    }
  },

  del: async (url: string, id: number) => {
    const localToken =
      localStorage.getItem('ETWL') &&
      JSON.parse(localStorage.getItem('ETWL') || '')?.token
    try {
      return await axios.delete(
        `${process.env.REACT_APP_API_URL}/${url}/${id}`,
        {
          headers:
            localToken && localToken !== ''
              ? { Authorization: `Bearer ${localToken}` }
              : {},
        }
      )
    } catch (err: any) {
      throw err
    }
  },

  checkLogin: async () => {
    const localToken =
      localStorage.getItem('ETWL') &&
      JSON.parse(localStorage.getItem('ETWL') || '')?.token
    try {
      return await axios.get(
        `${process.env.REACT_APP_API_URL}/users/me?${createQuery({
          fields: [
            'id',
            'username',
            'email',
            'provider',
            'confirmed',
            'first_name',
            'last_name',
            'phone',
            'how_did_you_hear',
            'first_name',
            'last_name'
          ],
          populate: {
            business_role: {
              populate: {
                fields: ['id', 'name'],
              },
            },
            wl_shop: {
              fields: ['id', 'connected', 'active'],
              populate: {
                wl_products: {
                  fields: ['name'],
                  filters: {
                    publishedAt: {
                      $notNull: true,
                    }
                  }
                },
              }
            },
            wl_member_type: {
              fields: ['name'],
            },
          },
        })}`,
        {
          headers: {
            Authorization: `Bearer ${localToken}`,
          },
        }
      )
    } catch (err) {
      throw err
    }
  },

  getCountries: async () => {
    try {
      return await axios.get(
        `https://countriesnow.space/api/v0.1/countries/flag/images`
      )
    } catch (err: any) {
      throw err
    }
  },

  uploadImage: async (image: File, cancelToken?: CancelToken) => {
    const localToken =
      localStorage.getItem('ETWL') &&
      JSON.parse(localStorage.getItem('ETWL') || '')?.token

    const formData = new FormData()
    formData.append('files', image)

    return await axios.request({
      url: process.env.REACT_APP_API_URL + '/upload',
      method: 'POST',
      headers:
        localToken && localToken !== ''
          ? { Authorization: `Bearer ${localToken}` }
          : {},
      data: formData,
      ...(cancelToken ? { cancelToken } : {}),
    })
  },

  uploadMultiImage: async (images: FileList) => {
    const localToken =
      localStorage.getItem('ETWL') &&
      JSON.parse(localStorage.getItem('ETWL') || '')?.token

    const formData = new FormData()
    for (let i = 0; i < images.length; i++) {
      formData.append('files', images[i])
    }

    return await axios.request({
      url: process.env.REACT_APP_API_URL + '/upload',
      method: 'POST',
      headers:
        localToken && localToken !== ''
          ? { Authorization: `Bearer ${localToken}` }
          : {},
      data: formData,
    })
  },

  upVote: async (prdId: number, spPrdId?: string) => {
    const localToken =
      localStorage.getItem('ETWL') &&
      JSON.parse(localStorage.getItem('ETWL') || '')?.token
    try {
      return await axios.post(
        `${process.env.REACT_APP_API_URL}/upVote`,
        {
          product_id: prdId,
          ...(spPrdId ? {sp_product_id: spPrdId} : {}),
          date: moment().format('YYYYMMDD'),
        },
        {
          headers:
            localToken && localToken !== ''
              ? { Authorization: `Bearer ${localToken}` }
              : {},
        }
      )
    } catch (err: any) {
      throw err
    }
  },

  unVote: async (prodId: number, votedId: number) => {
    const localToken =
      localStorage.getItem('ETWL') &&
      JSON.parse(localStorage.getItem('ETWL') || '')?.token
    try {
      return await axios.post(
        `${process.env.REACT_APP_API_URL}/unVote`,
        {
          prodId,
          votedId,
        },
        {
          headers:
            localToken && localToken !== ''
              ? { Authorization: `Bearer ${localToken}` }
              : {},
        }
      )
    } catch (err: any) {
      throw err
    }
  },
}

export default apis
