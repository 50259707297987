import MyRoutes from './routers'
// import s from './app.module.css'

function App() {
  return (
    // <div className={`bg-white font-body min-h-[100vh] overflow-x-hidden ${s.scrollBar}`}>
    <div className={`bg-white font-body`}>
      <MyRoutes />
    </div>
  )
}

export default App
