/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import Page404 from '../app/not-found'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Page } from './types'
import ClipsPage from '../app/ClipPage/page'
import HomePageCMS from '../app/HomePage'
import SiteHeader from '../app/SiteHeader'
import HeaderBar from 'components/HeaderBar'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setLogo, setNavItems } from 'features/global/headerSlice'
import { setFooterMenu, setFooterSubMenu } from 'features/global/footerSlice'
import { setHeaderBar } from 'features/global/headerbarSlice'
import { createQuery } from 'utils/function'
import SubmitYourPrd from 'components/ETWComponent/SubmitYourPrd'
import ProductDetail from 'app/ProductDetail'
import PageTag from 'app/TagPage/page'
import PageSearch from 'app/SearchPage/page'
import { RootState } from 'app/store'
import { setOpenModalLogin } from 'features/authenticate/loginSlice'
import LoginModal from 'components/Login'
import Dashboard from 'components/ETDashboard'
import DashboardLayout from 'components/ETDashboard/Layout'
import MyVotes from 'app/MyVotes'
import BrandClip from 'components/ETDashboard/Clip'
import SettingsUser from 'components/ETDashboard/Settings'
import ProductClipPage from 'app/ProductClipPage'
import UnderConstruction from 'app/under-construction'
import TermOfUsePage from 'app/StaticPage/TermOfUse'
import apis from 'apis'
// import MyVoteCuration from 'app/MyVotesCuration'
import MyBookmarks from 'app/MyBookmarks'
import BrandProduct from 'components/ETDashboard/Product/list'
import CurationProductDetail from 'app/CurationProductDetail'
import { setCompanyInfo } from 'features/global/companyDefaultSlice'
import CurationProductDetailPreview from 'app/CurationProductDetailPreview'
import ProductDetailPreview from 'app/ProductDetailPreview'
import Inview from 'app/Inview'

interface StaticPageType {
  title: string
  slug: string
  desc: string
}

export const pages: Page[] = [
  { path: '/', component: HomePageCMS },
  { path: '/brands', component: HomePageCMS },
  { path: '/products/:slug', component: CurationProductDetail },
  { path: '/preview/products/:slug', component: CurationProductDetailPreview },
  { path: '/brands-products/:slug', component: ProductDetail },
  { path: '/preview/brands-products/:slug', component: ProductDetailPreview },
  // { path: '/my-votes', component: MyVoteCuration },
  { path: '/my-votes', component: MyVotes },
  { path: '/my-bookmarks', component: MyBookmarks },
  { path: '/guides', component: UnderConstruction },
  { path: '/clips', component: ClipsPage },
  { path: '/clips/:slug', component: ClipsPage },
  { path: '/submit-your-product', component: SubmitYourPrd },
  { path: '/tag/:slug', component: PageTag },
  { path: '/product-clips/:slug', component: ProductClipPage },
  { path: '/product-search/', component: PageSearch },
  { path: '/product-search/:searchText', component: PageSearch },
  { path: '/connect/:provider/redirect', component: HomePageCMS },
  { path: '/reset-password', component: HomePageCMS },
  { path: '/email-confirm', component: HomePageCMS },
  { path: '/login-without-password', component: HomePageCMS },
  { path: '/inview', component: Inview },
  { path: '/#', component: HomePageCMS },
  {
    path: '/brand-dashboard',
    component: () => (
      <DashboardLayout>
        <Dashboard />
      </DashboardLayout>
    ),
  },
  {
    path: '/brand-products',
    component: () => (
      <DashboardLayout>
        <BrandProduct />
      </DashboardLayout>
    ),
  },
  {
    path: '/brand-clips',
    component: () => (
      <DashboardLayout>
        <BrandClip />
      </DashboardLayout>
    ),
  },
  {
    path: '/brand-settings',
    component: () => (
      <DashboardLayout>
        <SettingsUser />
      </DashboardLayout>
    ),
  },
]

const MyRoutes = () => {
  const dispatch = useDispatch()
  const openModal = useSelector(
    (state: RootState) => state.openModalLogin.openModalLogin
  )
  const [staticPage, setStaticPage] = useState<StaticPageType[]>([])

  const getGlobalData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/global?${createQuery({
          populate: {
            global_header: {
              populate: {
                logo: {
                  fields: ['name', 'url'],
                },
                navigations: {
                  fields: ['title', 'slug', 'emoji', 'isNew'],
                },
              },
            },
            global_footer: {
              populate: {
                menu: {
                  fields: ['title', 'slug', 'emoji', 'isNew'],
                },
                submenu: {
                  fields: ['title', 'slug', 'emoji', 'isNew'],
                },
              },
            },
            header_bar: {
              populate: {
                content: {
                  populate: {
                    image: {
                      fields: ['name', 'url'],
                    },
                  },
                },
                options: '*',
              },
            },
            companyInfo: {
              populate: {
                logo: {
                  fields: ['url']
                }
              }
            }
          },
        })}`
      )
      .then((res) => {
        const { attributes } = res.data.data

        if (attributes) {
          const { header_bar, companyInfo } = attributes
          const { logo, navigations } = attributes.global_header
          const { menu, submenu } = attributes.global_footer
          logo.data &&
            logo.data.attributes &&
            dispatch(setLogo(logo.data.attributes))
          navigations.data &&
            navigations.data &&
            dispatch(
              setNavItems(
                navigations.data.reduce((acc: any, item: any) => {
                  const { id, attributes } = item
                  acc.push({
                    id,
                    ...attributes,
                  })
                  return acc
                }, [])
              )
            )

          dispatch(
            setFooterMenu(
              menu.data.reduce((acc: any, item: any) => {
                const { id, attributes } = item
                acc.push({
                  id,
                  ...attributes,
                })
                return acc
              }, [])
            )
          )

          dispatch(
            setFooterSubMenu(
              submenu.data.reduce((acc: any, item: any) => {
                const { id, attributes } = item
                acc.push({
                  id,
                  ...attributes,
                })
                return acc
              }, [])
            )
          )

          dispatch(setHeaderBar(header_bar))

          dispatch(setCompanyInfo(companyInfo))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleGetStaticPage = () => {
    apis
      .get(
        'wl-static-pages',
        createQuery({
          sort: {
            slug: 'asc',
          },
        })
      )
      .then((res) => {
        if (res.data.data.length > 0) {
          setStaticPage(
            res.data.data.reduce((acc: StaticPageType[], item: any) => {
              acc.push({
                title: item.attributes.title,
                slug: item.attributes.slug,
                desc: item.attributes.desc,
              })
              return acc
            }, [])
          )
        }
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    getGlobalData()
    handleGetStaticPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BrowserRouter>
      <LoginModal
        open={openModal}
        onClose={() => dispatch(setOpenModalLogin(false))}
      />
      <HeaderBar />
      <SiteHeader />
      <Routes>
        {pages.map(({ component: Component, path }, index) => {
          return <Route key={index} element={<Component />} path={path} />
        })}
        {staticPage.length > 0 &&
          staticPage.map((_i, idx) => (
            <Route
              key={idx}
              path={`/${_i.slug}`}
              element={<TermOfUsePage value={_i} />}
            />
          ))}
        <Route path={'/404'} element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  )
}

export default MyRoutes
