export const list =  [
  {
    name: 'day',
    slug: 'day',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          className={`fill-[--gray-text]`}
          d="M11.25 0.5L8.901 1.12L7.3245 0.5L4.75 9.0885H7.46625L5.088 15.5L10.9125 6.912H7.4065L11.25 0.5Z"
          
        />
      </svg>
    ),
  },
  {
    name: 'week',
    slug: 'week',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M5.95336 1.33334H5.86003C5.30669 1.34667 5.13336 2.08 5.60003 2.37334C6.54664 2.97549 7.32587 3.80692 7.86547 4.79054C8.40506 5.77416 8.68753 6.8781 8.68669 8C8.68669 10.3667 7.46003 12.44 5.60669 13.62C5.13336 13.9267 5.32003 14.6533 5.88003 14.6667H6.02003C10.0534 14.6667 13.26 11.0733 12.6 6.91334C12.0934 3.68667 9.22003 1.3 5.95336 1.33334Z"
          className={`fill-[--gray-text]`}
        />
      </svg>
    ),
  },
  {
    name: 'month',
    slug: 'month',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M8.00001 14.61L6.04601 12.6667H3.33335V9.954L1.39001 8L3.33335 6.046V3.33333H6.04601L8.00001 1.39L9.95401 3.33333H12.6667V6.046L14.61 8L12.6667 9.954V12.6667H9.95401L8.00001 14.61ZM8.00001 10.8207C8.78046 10.8207 9.44557 10.5456 9.99535 9.99533C10.5451 9.44511 10.8202 8.78 10.8207 8C10.8207 7.21955 10.5456 6.55444 9.99535 6.00467C9.44513 5.45489 8.78001 5.17978 8.00001 5.17933C7.21957 5.17933 6.55446 5.45444 6.00468 6.00467C5.4549 6.55489 5.17979 7.22 5.17935 8C5.17935 8.78044 5.45446 9.44555 6.00468 9.99533C6.5549 10.5451 7.22002 10.8202 8.00001 10.8207ZM8.00001 13.6667L9.66668 12H12V9.66667L13.6667 8L12 6.33333V4H9.66668L8.00001 2.33333L6.33335 4H4.00001V6.33333L2.33335 8L4.00001 9.66667V12H6.33335L8.00001 13.6667Z"
          className={`fill-[--gray-text]`}
        />
      </svg>
    ),
  },
  {
    name: 'year',
    slug: 'year',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M13.69 5.4C13.89 4.96 13.995 4.483 14 4H6C6.006 4.484 6.115 4.961 6.32 5.4C6.528 5.85 6.835 6.247 7.22 6.56L9.04 8.08C9.32 8.314 9.546 8.608 9.7 8.94C9.86 9.27 9.941 9.633 9.94 10C9.941 10.367 9.86 10.73 9.7 11.06C9.54611 11.3918 9.32073 11.6855 9.04 11.92L7.22 13.44C6.83544 13.753 6.52759 14.1497 6.32 14.6C6.11516 15.0387 6.00608 15.5159 6 16H14C13.9947 15.5169 13.8891 15.0402 13.69 14.6C13.476 14.1509 13.1652 13.7548 12.78 13.44L11 11.91C10.7234 11.678 10.5014 11.3878 10.35 11.06C10.1733 10.7336 10.0741 10.3709 10.06 10C10.0741 9.6291 10.1733 9.26642 10.35 8.94C10.5014 8.61223 10.7234 8.32198 11 8.09L12.78 6.56C13.1652 6.24524 13.476 5.84909 13.69 5.4Z"
          className={`fill-[--gray-text]`}
        />
      </svg>
    ),
  },
]