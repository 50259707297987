import React from 'react'
import './index.css'
import './styles/index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {AdminProvider} from 'context/adminContext'
import {Provider} from 'react-redux'
import { store } from 'app/store'
import ReactDOM from 'react-dom/client'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <AdminProvider>
            <App/>
        </AdminProvider>
    </Provider>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()