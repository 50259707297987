import React from 'react'
import { Link } from 'react-router-dom'
import { DefaultThumbnail, UnVotedIcon, VotedIcon } from 'utils/svgExport'

interface IProductCardProps {
  id: number
  name: string
  slug: string
  thumbnail?: string
  hoverVideo?: string
  dateVoted?: string
  countVoted?: number
  summary: string
  onUnVote?: () => void
  disabled?: boolean
}

function MyVotesCard({
  id,
  name,
  thumbnail,
  hoverVideo,
  dateVoted,
  countVoted = 0,
  summary = '',
  onUnVote,
  disabled = false,
  slug,
}: IProductCardProps) {
  return (
    <>
      <div className="group relative flex  w-full min-w-[300px] max-w-full rounded-[10px] p-0 cursor-pointer border-[--gray-line] border-[1px] max-[768px]:border-transparent hover:bg-[--gray-bg-hover] hover:shadow-[0_4px_4px_0_#0000000D] max-[768px]:hover:bg-white max-[768px]:hover:shadow-none">
        <Link to={slug} state={{ id }}>
          <div
            className={`group/imagesContent  flex items-center justify-center overflow-hidden  rounded-l-[10px]  rounded-r-[10px] mr-5 mt-4 h-[72px] w-[72px] shrink-0 cursor-pointer  md:mr-[30px] md:mt-0 md:h-[160px] md:w-[160px] md:rounded-r-none`}
          >
            {hoverVideo && (
              <video
                className={`h-full w-full object-cover hidden group-hover/imagesContent:block rounded-l-[10px]  rounded-r-[10px] md:rounded-r-none`}
                loop
                playsInline
                autoPlay
                muted
              >
                <source
                  src={`${process.env.REACT_APP_URL_BE}${hoverVideo}`}
                  type="video/mp4"
                />
              </video>
            )}
            <img
              src={
                thumbnail
                  ? `${process.env.REACT_APP_URL_BE}${thumbnail}`
                  : DefaultThumbnail
              }
              className={`h-full max-h-full w-full max-w-full object-cover  ${
                hoverVideo ? 'group-hover/imagesContent:hidden' : ''
              }  rounded-l-[10px]  rounded-r-[10px] md:rounded-r-none`}
              alt=""
            />
          </div>
        </Link>
        <Link to={slug} state={{ id }}>
          <div className="mr-4 w-full min-w-0 overflow-hidden md:flex md:flex-col md:justify-center md:py-1 text-ellipsis">
            <div className="mt-1.5 flex items-center text-title-18 text-[--gray-text] md:mt-2">
              <div className=" w-full text-ellipsis line-clamp-1">
                {`You Earned: ${summary}`}
              </div>
            </div>
            <div className="break-word mt-[11px] text-sm font-medium leading-[1.4] text-gray-2350 line-clamp-2">
              {' '}
              Enjoy this exclusive discount from{` ${name} `}as a thank you for
              boosting their exposure on Wishlist.{' '}
            </div>
            <div className="flex flex-wrap">
              <div className="mr-2.5 mt-2.5">
                <button
                  type="button"
                  className="h-auto min-h-[32px] font-[RobotoBold] w-fit rounded-[4px] bg-[--brand-primary] px-[8px] py-[6px] R-home-content text-white hover:bg-[--brand-primary-hover] active:bg-[--brand-primary-pressed]"
                >
                  Get Discount Code
                </button>
              </div>
            </div>
          </div>
        </Link>
        <div
          onClick={() => {
            !disabled && onUnVote && onUnVote()
          }}
          className="group/unvote ml-auto flex h-[110px] shrink-0 flex-col items-center border-l border-gray-1350 pl-4 md:mt-[25px] md:w-[130px] md:p-0 w-24 "
        >
          <img
            src={VotedIcon}
            width="26"
            height="26"
            className="mt-2 group-hover/unvote:hidden"
            alt=""
          />
          <img
            src={UnVotedIcon}
            width="26"
            height="26"
            className="mt-2 hidden group-hover/unvote:block h-[26px] w-[26px]"
            alt=""
          />
          <div className="text-base font-bold leading-none text-slate-1150 md:text-xl md:leading-none mt-[8px] font-[RobotoBold]">
            {countVoted}
          </div>
          <div className="font-medium leading-none text-slate-1150 text-xs mt-[24px] group-hover/unvote:hidden text-center">
            {' '}
            {`Voted: ${dateVoted}`}
          </div>
          <div className="mt-[21px] hidden text-xs font-medium leading-none text-slate-1150 group-hover/unvote:block">
            {' '}
            Unvote
          </div>
        </div>
        <canvas
          width="824"
          height="200"
          className="confetti-canvas pointer-events-none absolute left-0 top-0 h-full w-full"
        ></canvas>
      </div>
    </>
  )
}

export default MyVotesCard
