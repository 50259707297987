import { Link } from 'react-router-dom'
import React, { useEffect } from 'react'

const UnderConstruction: React.FC = () => {
  useEffect(() => {
    document.title = 'ET Wishlist | Under Construction'
  }, [])

  return (
    <div className="nc-Page404">
      <div className="container relative py-16 lg:py-20">
        {/* HEADER */}
        <header className="flex flex-col text-center max-w-2xl mx-auto space-y-7">
          <h2 className="text-7xl md:text-8xl">&#128679;</h2>
          <span className="uppercase product-title-22 bg">
            {`This feature is under construction.`}
          </span>
          <Link to="/" className="mt-4 font-bold">
            Return Home Page
          </Link>
        </header>
      </div>
    </div>
  )
}

export default UnderConstruction
