export const BACKGROUND_COLOR = [
  '#3498db',
  '#e74c3c',
  '#2ecc71',
  '#f39c12',
  '#9b59b6',
  '#34495e',
  '#d35400',
  '#27ae60',
  '#c0392b',
  '#8e44ad',
]