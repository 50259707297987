/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { convertKeys, createQuery, formatDataNumber } from 'utils/function'
import CardSlider, {
  PrdDetailDataType,
} from '../../components/ETWComponent/SliderPost/CardSlider'
import 'components/PrdDetailCommon/ProductDetail.css'
import CustomButton from 'app/ClipPage/CustomButton'
import AwardItem from 'components/Award/AwardItem'
import LoadingScreen from 'components/LoadingScreen'
import {
  FAQ,
  MoreInfo,
  OurStory,
  PrdDesc,
  PrdGalleryModal,
  PrdMedia,
  SharePrdModal,
  VendorDesc,
  VoteLandMobile,
  WULI,
} from 'components/PrdDetailCommon'
import {
  IFAQ,
  IProductState,
  prdDetailState,
} from 'components/PrdDetailCommon/prdDetailInterface'
import { useAdminContext } from 'context/adminContext'
import { setOpenModalLogin } from 'features/authenticate/loginSlice'
import useUserLogin from 'hooks/useUserLogin'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import {
  CurationBookmark,
  CurationBookmarked,
  CurationShare,
  HotDeal,
  VotedIcon,
} from 'utils/svgExport'
import apis from '../../apis'
import DefaultImage from '../../assets/svg/DefaultImage.svg'
import WorldIcon from '../../assets/svg/world_icon.svg'
import IconTextButton from 'components/IconTextButton'
import NotFound from 'components/NotFound'
import IconButtonVoteAnimation from '../../components/IconButtonVoteAnimation'

const CurationProductDetail = () => {
  const matchRoute = useParams()
  const [prdDetail, setPrdDetail] = useState<prdDetailState>({
    voted: false,
    showPrdQT: 'Features',
    showStory: false,
    showGallery: false,
    showSharePrd: false,
    selectedGlrImg: -1,
    discount: {
      title: '',
      summary: '',
      discount_code: '',
      discount_value: '',
      status: 'ACTIVE',
      voteId: 0,
      votes: 0,
    },
  })
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const [sameCate, setSameCate] = useState<PrdDetailDataType[] | []>([])
  const prdDescRef = useRef<HTMLDivElement>(null)

  const initIImage = {
    id: 0,
    attributes: {
      name: '',
      url: '',
    },
  }
  const [product, setProduct] = useState<IProductState>({
    id: 0,
    attributes: {
      name: '',
      slug: '',
      productLink: '',
      headline: '',
      subHeadline: '',
      thumbnail: {
        data: initIImage,
      },
      hoverVideo: {
        data: initIImage,
      },
      discountType: 0,
      pageSubmitted: false,
      lastProdOfDay: 0,
      lastProdOfMonth: 0,
      lastProdOfWeek: 0,
      lastProdOfYear: 0,
      launchDate: new Date(),
      launchType: 0,
      launchStatus: 0,
      shopVariantId: 0,
      shopVariantPrice: 0,
      shopVariantCurrency: 'USD',
      shop: {
        data: {
          id: 0,
          attributes: {
            domain: '',
            storefrontAccessToken: '',
          },
        },
      },
      shopInfo: {
        shopProduct: {
          data: {},
        },
        productPageLink: '',
        productReviewsLink: '',
      },
      prodDtl: {
        productHeadline: '',
        productPageHeadline: '',
        productPageDesc: '',
        productPageLink: '',
        productReviewsLink: '',
      },
      wyli: {
        reason1: '',
        reason2: '',
        reason3: '',
        reason4: '',
        reason5: '',
      },
      images: {
        data: [],
      },
      videos: {
        data: [],
      },
      shortClips: {
        data: [],
      },
      ourStory: {
        founderName: '',
        founderImage: {
          data: initIImage,
        },
        storyHeadline: '',
        storyDesc: '',
      },
      moreInfo: {
        features: '',
        shippingInfo: '',
        returnExchangePolicy: '',
        whatIncluded: '',
        whatIncludedTitle: "What's Included",
      },
      faqs: [],
      adminNotes: [],
      votes: 0,
      views: 0,
      shares: 0,
      shopProductId: '',
      category: {
        data: null,
      },
      tags: {
        data: [],
      },
      value: {
        data: [],
      },
      storyClip: {
        data: {
          id: 0,
          attributes: {
            clip: {
              data: {
                id: 0,
                attributes: {
                  name: '',
                  url: '',
                },
              },
            },
          },
        },
      },
      publishedAt: new Date(),
      productType: '',
    },
    isBookmarked: false,
  })
  const closeGallery = useRef()
  const closeSharePrd = useRef()
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const { showAlert } = useAdminContext()
  const [loadingBookmark, setLoadingBookmark] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [currentExpand, setCurrentExpand] = useState(1)
  const { token } = useUserLogin()

  const handleGetPageData = () => {
    apis
      .get(
        `wl-products`,
        createQuery({
          isDetail: true,
          filters: {
            slug: {
              $eq: matchRoute.slug,
            },
            page_submitted: true,
            page_approved: true,
            product_type: 'Single',
          },
          populate: {
            hover_video: {
              fields: ['name', 'url'],
            },
            thumbnail: {
              fields: ['name', 'url'],
            },
            shop: {
              fields: ['id'],
            },
            wyli: '*',
            images: {
              fields: ['name', 'url'],
            },
            short_clips: {
              populate: {
                clip: {
                  fields: ['name', 'url'],
                },
              },
            },
            videos: {
              fields: ['name', 'url'],
            },
            our_story: {
              populate: {
                founder_image: {
                  fields: ['name', 'url'],
                },
              },
            },
            more_info: '*',
            faqs: {
              fields: ['question', 'answer'],
            },
            category: {
              fields: ['name', 'slug'],
            },
            tags: {
              fields: ['name', 'slug'],
            },
            values: {
              fields: ['name', 'slug'],
            },
            story_clip: {
              populate: {
                clip: {
                  fields: ['name', 'url'],
                },
              },
            },
            prod_dtl: '*',
          },
        })
      )
      .then((res) => {
        const pageInfo = res.data.data
        if (pageInfo.length > 0) {
          setProduct(convertKeys(pageInfo[0]))
          setPrdDetail((preState) => ({
            ...preState,
            voted: pageInfo[0].status ? pageInfo[0].status.voteId !== 0 : false,
            discount: {
              ...preState.discount,
              discount_code: pageInfo[0].status?.discountCode,
              status: pageInfo[0].status?.status || '',
              voteId: pageInfo[0].status?.voteId || 0,
              discount_value: pageInfo[0].status
                ? `${Number(pageInfo[0].status?.value) * 100}`
                : '',
              votes: pageInfo[0].attributes.votes,
            },
          }))
        } else {
          showAlert('warning', 'Product not found')
          // navigate('/404')
        }
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  const handleGetOtherProductSameCate = () => {
    apis
      .get(
        'wl-products',
        createQuery({
          filters: {
            category: {
              id: product?.attributes?.category.data?.id,
            },
            $or: [
              {
                id: {
                  $notIn: [product?.id],
                },
              },
              {
                slug: {
                  $notIn: [`${matchRoute.slug}`],
                },
              },
            ],
            // shop: {
            //   id: { $notIn: [product?.attributes?.shop?.data?.id] },
            // },
            product_type: 'Single',
            page_submitted: true,
            page_approved: true,
          },
          populate: {
            thumbnail: {
              fields: ['url'],
            },
            category: '*',
          },
          pagination: {
            start: 0,
            limit: 5,
          },
          sort: {
            votes: 'desc',
            createdAt: 'desc',
          },
        })
      )
      .then((res) => {
        const sameCateList = res.data.data.map((_i: any) => {
          const award = []
          _i?.attributes?.last_prod_of_week && award.push(1)
          _i?.attributes?.last_prod_of_month && award.push(2)
          _i?.attributes?.last_prod_of_year && award.push(3)

          return {
            votedCount: _i?.attributes?.votes,
            award: award,
            title: _i?.attributes?.name,
            image: _i.attributes?.thumbnail?.data
              ? `${process.env.REACT_APP_URL_BE}${_i.attributes?.thumbnail?.data?.attributes?.url}`
              : DefaultImage,
            href: _i.attributes.slug,
          }
        })
        setSameCate(sameCateList)
      })
      .catch((err) => {
        console.log(err.message)
        showAlert('error', 'Something went wrong! Cannot get product info!')
      })
  }

  const handleBookmark = (
    productId: number,
    type: 'bookmark' | 'unbookmark'
  ) => {
    if (!token) {
      dispatch(setOpenModalLogin(true))
      showAlert('warning', 'This features require logging in to use')
    } else {
      setLoadingBookmark(true)
      apis
        .post(`wl-bookmarks/${type}`, {
          data: {
            product_id: productId,
          },
        })
        .then((res) => {
          showAlert(
            'success',
            type === 'bookmark' ? 'Bookmarked!' : 'UnBookmarked!'
          )
          setLoadingBookmark(false)
          setProduct((preState) => {
            return {
              ...preState,
              isBookmarked: type === 'bookmark',
            }
          })
        })
        .catch((err) => {
          console.log(err)
          setLoadingBookmark(false)
          showAlert(
            'error',
            type === 'bookmark'
              ? 'Bookmarked failed! Please try again later!'
              : 'UnBookmarked failed! Please try again later!'
          )
        })
    }
  }

  const handleVote = () => {
    if (!token) {
      dispatch(setOpenModalLogin(true))
      showAlert('warning', 'This features require logging in to use')
    } else {
      if (product) {
        setDisabled(true)
        apis
          .upVote(product.id)
          .then((res) => {
            showAlert('success', 'Vote successfully')
            setPrdDetail((preState) => ({
              ...preState,
              voted: true,
              discount: {
                ...preState.discount,
                votes: res.data.votes || 0,
                // discount_code: res.data.wlVote.discount_code,
                voteId: res.data.wlVote.id,
              },
            }))

            setDisabled(false)
          })
          .catch((err) => {
            console.log(err)
            setDisabled(false)
            showAlert('error', 'Something went wrong! Please try again later')
          })
      }
    }
  }

  const handleBuyNow = () => {
    window.open(
      product?.attributes.prodDtl?.productPageLink
        ? product.attributes.prodDtl.productPageLink
        : '/',
      '_blank'
    )
  }

  const renderPrdQT = () => {
    const PrdMoreInfo = [
      {
        id: 1,
        title: 'Features',
        data: product?.attributes.moreInfo?.features,
      },
      {
        id: 2,
        title: product?.attributes.moreInfo?.whatIncludedTitle as string,
        data: product?.attributes.moreInfo?.whatIncluded,
      },
      {
        id: 3,
        title: 'Shipping',
        data: product?.attributes.moreInfo?.shippingInfo,
      },
      {
        id: 4,
        title: 'Returns',
        data: product?.attributes.moreInfo?.returnExchangePolicy,
      },
    ]

    return (
      <div className="mt-[43px] md:mt-0">
        {PrdMoreInfo.map((_i, idx) => {
          return _i.data && String(_i.data).trim() !== '' ? (
            <MoreInfo
              title={_i.title}
              data={_i.data}
              key={idx}
              isExpanded={currentExpand === _i.id}
              onExpand={() => {
                if (currentExpand === _i.id) {
                  setCurrentExpand(0)
                } else {
                  setCurrentExpand(_i.id)
                }
              }}
            />
          ) : null
        })}
        <MoreInfo
          isExpanded={currentExpand === 5}
          onExpand={() => {
            if (currentExpand === 5) {
              setCurrentExpand(0)
            } else {
              setCurrentExpand(5)
            }
          }}
          title="FAQs"
          children={
            product ? (
              product.attributes.faqs.map((item: IFAQ) => (
                <FAQ
                  answer={item.answer}
                  question={item.question}
                  key={item.id}
                />
              ))
            ) : (
              <></>
            )
          }
        />
      </div>
    )
  }

  const renderHeaderPrdDetail = () => {
    return (
      <div className="hidden md:block">
        <div className="mb-[13px] mt-1 inline-block cursor-pointer text-sm font-bold leading-none tracking-tight text-[--gray-text] md:hidden">
          Back to Feed
        </div>
        <div className="flex justify-between break-normal md:min-h-[62px]">
          <div className="mr-1 min-w-0 self-start">
            <div className="flex items-center">
              <div className="overflow-hidden text-ellipsis break-words product-title-22 text-[--gray-text] md:whitespace-nowrap h-[32px]">
                {product?.attributes.name}
              </div>
            </div>
            <h2 className="mt-[2px] break-words R-body-box text-[--gray-text] md:truncate">
              {product?.attributes.prodDtl?.productHeadline}
            </h2>
          </div>
          <div className="h-14 grow items-center justify-end self-end flex space-x-3">
            <IconTextButton
              className="text-menu-15 text-[--gray-text] sm:!flex !hidden"
              startIcon={
                <img src={CurationShare} alt="" width={'24'} height={'24'} />
              }
              label="Share"
              onClick={() => {
                document.body.classList.add('overflow-hidden')
                setPrdDetail({
                  ...prdDetail,
                  showSharePrd: !prdDetail.showSharePrd,
                })
              }}
              whenHiddenLabel="lg"
            />

            <div
              className="cursor-pointer"
              onClick={
                !loadingBookmark && product
                  ? !product.isBookmarked
                    ? () => handleBookmark(product?.id, 'bookmark')
                    : () => handleBookmark(product?.id, 'unbookmark')
                  : () => {}
              }
            >
              <img
                className="h-7 w-7"
                src={
                  product?.isBookmarked ? CurationBookmarked : CurationBookmark
                }
                alt=""
              />
            </div>
            <CustomButton
              className={`!h-[42px] group space-x-2`}
              disabled={disabled}
              type={prdDetail.voted ? 'normal' : 'primary'}
              label={
                <React.Fragment>
                  <div className="md:flex hidden whitespace-nowrap">
                    {prdDetail.voted ? 'Buy Now' : 'Upvote'}
                  </div>
                  <div className="w-6 h-6">
                    {prdDetail.voted ? (
                      <img src={VotedIcon} alt="" />
                    ) : (
                      <IconButtonVoteAnimation />
                    )}
                  </div>
                  <div>
                    {prdDetail?.discount?.votes
                      ? formatDataNumber(prdDetail.discount.votes, 0)
                      : 0}
                  </div>
                </React.Fragment>
              }
              onClick={
                !disabled
                  ? !prdDetail.voted
                    ? handleVote
                    : handleBuyNow
                  : () => {}
              }
            />
          </div>
        </div>
      </div>
    )
  }

  const renderAwards = () => {
    const lastProdOfMonth = product?.attributes?.lastProdOfMonth
    const lastProdOfWeek = product?.attributes?.lastProdOfWeek
    const lastProdOfYear = product?.attributes?.lastProdOfYear

    return lastProdOfMonth || lastProdOfWeek || lastProdOfYear ? (
      <div className="flex space-x-3">
        {lastProdOfWeek && <AwardItem type="week" />}
        {lastProdOfMonth && <AwardItem type="month" />}
        {lastProdOfYear && <AwardItem type="year" />}
      </div>
    ) : null
  }

  useEffect(() => {
    if (product?.attributes.prodDtl?.productPageDesc && prdDescRef.current) {
      prdDescRef.current.innerHTML =
        product?.attributes.prodDtl?.productPageDesc.replaceAll('\n', '<br/>') +
        ''
    }

    if (product) {
      handleGetOtherProductSameCate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, prdDescRef])

  useEffect(() => {
    handleGetPageData()
    const handleResize = (e: any) => {
      setWindowSize({
        width: e.target.innerWidth,
        height: e.target.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href, token])

  const handleUpdateView = () => {
    apis
      .post('wl-prod-views', {
        data: {
          product_id: product?.id,
          date: moment().format('YYYYMMDD'),
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (product && product?.id > 0) {
      handleUpdateView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.id])

  useEffect(() => {
    const hiddenOurStory = () => {
      document.body.classList.remove('overflow-hidden')
      setPrdDetail({ ...prdDetail, showStory: !prdDetail.showStory })
    }
    const hiddenGaleryModal = () => {
      document.body.classList.remove('overflow-hidden')
      setPrdDetail({
        ...prdDetail,
        showGallery: !prdDetail.showGallery,
        selectedGlrImg: -1,
      })
    }
    const hiddenSharePrdModal = () => {
      document.body.classList.remove('overflow-hidden')
      setPrdDetail({ ...prdDetail, showSharePrd: !prdDetail.showSharePrd })
    }
    const handleKeyDown = (e: any) => {
      if (prdDetail.showStory && e.keyCode === 27) {
        hiddenOurStory()
      }
      if (prdDetail.showGallery && e.keyCode === 27) {
        hiddenGaleryModal()
      }
      if (prdDetail.showSharePrd && e.keyCode === 27) {
        hiddenSharePrdModal()
      }
    }
    document.getElementById('ourstoryModal') &&
      (document.getElementById('ourstoryModal')!.onclick = function (e) {
        if (prdDetail.showStory) {
          if (e.target === document.querySelector('#ourstoryModal > div')) {
            hiddenOurStory()
          }
        }
      })
    document.getElementById('sharePrdModal') &&
      (document.getElementById('sharePrdModal')!.onclick = function (e) {
        if (prdDetail.showSharePrd) {
          if (e.target === document.querySelector('#sharePrdModal > div')) {
            hiddenSharePrdModal()
          }
        }
      })

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [
    prdDetail.showStory,
    prdDetail.showGallery,
    prdDetail.showSharePrd,
    prdDetail,
  ])

  if (loading) return <LoadingScreen />

  return !loading && product.id === 0 ? (
    <div className="w-full mainContent">
      <NotFound />
    </div>
  ) : (
    <div className="w-full mainContent">
      <div className="md:p-4 w-full sm:mb-0">
        <div className="relative max-w-[1060px] px-1 md:mt-4 md:p-0 md:pb-0">
          {renderHeaderPrdDetail()}
          <PrdMedia
            product={product}
            setPrdDetail={setPrdDetail}
            prdDetail={prdDetail}
          />
        </div>
        <div className="w-full">
          <div className="px-4 md:px-0 w-full">
            {renderAwards()}
            <PrdDesc product={product} prdDescRef={prdDescRef} />
            <WULI product={product} />

            {/* {renderVideo()} */}
            <VendorDesc
              product={product}
              callback={() => {
                setPrdDetail({
                  ...prdDetail,
                  showStory: !prdDetail.showStory,
                })
              }}
            />
            {renderPrdQT()}
            <div className="flex items-center justify-center">
              <a
                className="font-bold text-center p-4 text-lg lg:text-xl bg-[--brand-primary] items-center justify-center text-white rounded-md !flex space-x-2 w-full mt-[50px]"
                target="_blank"
                href={
                  product?.attributes.prodDtl?.productPageLink
                    ? `${product?.attributes.prodDtl?.productPageLink}`
                    : '/'
                }
                rel="noreferrer"
              >
                <span>Get It Now!</span>
                <img alt="" src={HotDeal} className="h-5 w-5" />
              </a>
            </div>
            {sameCate.length > 0 ? (
              <CardSlider
                heading="You may like"
                posts={sameCate}
                perView={2}
                className="md:pb-[98px] border-t border-[--gray-line] pb-[195px] h-[539px] md:h-[442px] pt-6"
                prefixHref="products"
              />
            ) : (
              <div className="mt-[50px]"></div>
            )}
          </div>
        </div>
        <div className="el-overlay" style={{ display: 'none', zIndex: '2005' }}>
          <div
            role="dialog"
            aria-modal="true"
            aria-labelledby="el-id-3420-8"
            aria-describedby="el-id-3420-9"
            className="el-overlay-dialog"
            style={{ display: 'flex' }}
          ></div>
        </div>
      </div>
      {prdDetail.showStory ? (
        <OurStory
          setPrdDetail={setPrdDetail}
          prdDetail={prdDetail}
          product={product}
        />
      ) : null}
      {prdDetail.showGallery ? (
        <PrdGalleryModal
          product={product}
          setPrdDetail={setPrdDetail}
          prdDetail={prdDetail}
          closeGallery={closeGallery}
          windowSize={windowSize}
        />
      ) : null}
      {prdDetail.showSharePrd ? (
        <SharePrdModal
          setPrdDetail={setPrdDetail}
          prdDetail={prdDetail}
          closeSharePrd={closeSharePrd}
          product={product}
        />
      ) : null}
      <div className={`h-[200px] sm:h-0`}></div>
      <VoteLandMobile
        discountCode={prdDetail.discount.discount_code}
        prodLink={product.attributes.prodDtl?.productPageLink}
        prodPrice={product.attributes.shopVariantPrice}
        isVoted={prdDetail.voted}
        prodCurrency={
          product.attributes.shopVariantCurrency
            ? product.attributes.shopVariantCurrency
            : 'USD'
        }
        discountStatus={prdDetail.discount.status}
        votes={prdDetail.discount.votes}
        isBookmark={product.isBookmarked}
        prodName={product.attributes.name}
        disabled={disabled}
        handleVote={handleVote}
        handleBookmark={() => handleBookmark(product.id, 'bookmark')}
        handleUnBookmarked={() => handleBookmark(product.id, 'unbookmark')}
      />
    </div>
  )
}

export default CurationProductDetail
