import React, { useEffect } from 'react'
import { slugToTitle } from 'utils/function'
import './TermOfUse.css'

interface TermOfUsePageProps {
  value: { title: string; slug: string; desc: string }
}

const TermOfUsePage: React.FC<TermOfUsePageProps> = ({ value }) => {
  const mainRef = React.useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.innerHTML = value.desc
    }

    document.title = `ET Wishlist | ${slugToTitle(value.slug)}`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <section className="md:et-container et-container-sm">
      <main ref={mainRef} className="p-4"></main>
    </section>
  )
}

export default TermOfUsePage
