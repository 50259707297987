// import { IPagination } from 'data/types'
import React, { FC, Fragment, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import BackIcon from 'assets/svg/BackIconLong.svg'
import apis from 'apis'
import { createQuery, formatDataNumber, slugToTitle } from 'utils/function'
import DefaultImage from 'assets/images/default-thumbnail-sm.jpg'
import CustomButton from 'app/ClipPage/CustomButton'
import UpVote from 'assets/svg/UpVote.svg'
import { useAdminContext } from 'context/adminContext'

interface IProduct {
  id: number
  slug: string
  name: string
  votes: number
  views: number
  member_id: number
  shop_product_id: number
  voted?: boolean
  brand?: {
    name: string
    logo: {
      id: number
      name: string
      url: string
    }
  }
}

interface IProductClip {
  id: number
  attributes: {
    clip: {
      data: {
        id: number
        attributes: {
          name: string
          url: string
        }
      }
    }
    desc: string
    likes: number | null
    views: number | null
  }
}

const ProductClipPage: FC = () => {
  const navigate = useNavigate()
  const { slug } = useParams()
  const { showAlert } = useAdminContext()
  const [product, setProduct] = useState<IProduct>({
    id: 0,
    name: '',
    slug: '',
    views: 0,
    votes: 0,
    member_id: 0,
    shop_product_id: 0,
    brand: {
      logo: {
        id: 0,
        name: '',
        url: '',
      },
      name: '',
    },
    voted: true,
  })
  const [productClips, setProductClips] = useState<IProductClip[]>([])
  // const [pagination, setPagination] = useState<IPagination>()

  const handleVote = () => {
    if (product) {
      apis
        .upVote(product.id, product.shop_product_id + '')
        .then((res) => {
          showAlert('success', 'Vote successfully')
          setProduct({ ...product, voted: true })
        })
        .catch((err) => {
          console.log(err)
          showAlert('error', 'Something went wrong! Please try again later')
        })
    }
  }

  const getPageData = () => {
    apis
      .get(
        `wl-product-clips/${slug}`,
        createQuery({
          fields: ['desc', 'likes', 'views'],
          populate: {
            clip: {
              fields: ['name', 'url'],
            },
          },
        })
      )
      .then((res) => {
        // TODO: phân trang
        const { data: clips, product } = res.data
        setProduct(product)
        setProductClips(clips)
      })
      .catch((err) => {
        console.log(err.message)
        console.log(err.response.data.error)
      })
  }

  useEffect(() => {
    getPageData()

    document.title = `ET Wishlist | Product Clips: ${slugToTitle(slug)} `
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  return (
    <div className=" bg-white lg:et-container et-container-sm">
      <div className="flex flex-col w-full p-4">
        <div className="px-3.5 md:px-0">
          <div
            className="relative mb-[25px] mt-[37px] flex h-[38px] w-full items-center justify-center text-xl font-semibold tracking-tight text-black md:hidden"
            onClick={() => navigate('/')}
          >
            <button className="absolute left-0 top-0">
              <img src={BackIcon} alt="Back button" width="36" height="36" />
            </button>
            Clips
          </div>
          <div className="flex flex-col justify-between md:flex-row">
            <div className="mb-5 flex flex-row justify-between md:mb-0 md:flex-col">
              <div className="mr-2 flex flex-col md:flex-row md:items-center">
                <img
                  src={
                    product.brand?.logo
                      ? process.env.REACT_APP_URL_BE + product.brand.logo.url
                      : DefaultImage
                  }
                  alt="brand"
                  width="50"
                  height="50"
                  className="mb-2.5 h-9 w-9 rounded-full object-cover md:mb-0 md:mr-4 md:h-[50px] md:w-[50px]"
                />
                <div className="flex items-center">
                  <h1 className="product-title text-base font-bold leading-tight text-slate-1150 md:mr-0.5 md:text-2xl">
                    {product?.name}
                  </h1>
                </div>
              </div>
              <div className="mt-[23px] flex h-[42px] md:mt-[30px]">
                <div className="pr-5 text-sm font-normal leading-normal text-slate-1150">
                  <span className="font-bold">
                    {productClips.reduce((acc: number, item: IProductClip) => {
                      acc += item.attributes.views || 0
                      return acc
                    }, 0)}
                  </span>
                  <br /> Views
                </div>
                <div className="border-x border-gray-2500 px-5 text-sm font-normal leading-normal text-slate-1150">
                  <span className="font-bold">
                    {productClips.reduce((acc: number, item: IProductClip) => {
                      acc += item.attributes.likes || 0
                      return acc
                    }, 0)}
                  </span>
                  <br /> Likes
                </div>
                <div className="pl-5 text-sm font-normal leading-normal text-slate-1150">
                  <span className="font-bold">{productClips.length}</span>{' '}
                  <br /> Clips
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:self-end justify-end space-y-[11px] md:space-x-[11px]">
              {!product.voted && (
                <CustomButton
                  className="space-x-2"
                  label={
                    <Fragment>
                      <div>{'Upvote'}</div>
                      <img src={UpVote} alt="" />
                      <div>
                        {product.votes ? formatDataNumber(product.votes, 0) : 0}
                      </div>
                    </Fragment>
                  }
                  onClick={handleVote}
                />
              )}
              <Link to={`/products/${product.slug}`}>
                <CustomButton label="Visit Product Page" type="normal" />
              </Link>
            </div>
          </div>
          <div
            className="el-divider el-divider--horizontal mb-[25px] mt-[26px] w-full border-gray-1350 border-t-[1px]"
            role="separator"
          ></div>
          <div className="break-word mt-[23px] text-base font-bold leading-none text-slate-1150">
            <span>{product.name}'s </span>Clips
          </div>
          <div className="mt-4 grid grid-cols-3 gap-[5px] md:mt-[27px] md:grid-cols-5 md:gap-2">
            {productClips.map((item, idx) => (
              <Link
                to={`/clips/${product.slug}?ci=${item.id}`}
                className="relative aspect-[6/11] md:aspect-[41/76]"
                key={idx}
              >
                <video
                  src={
                    process.env.REACT_APP_URL_BE +
                    item.attributes.clip.data.attributes.url
                  }
                  className="h-full w-full rounded-md object-cover md:rounded-10"
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductClipPage
