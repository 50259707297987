
import MainNav2Logged from "./MainNav2Logged";
import React  from 'react'

export interface HeaderProps {}

export interface ILogo {
  name: string
  url: string
}

export interface INavigation {
  title: string
  slug: string
  emoji?: string
  isNew: boolean
}

export interface IFooter {
  menu: INavigation[]
  submenu: INavigation[]
}

const HeaderLogged: React.FC<HeaderProps> = () => {
 
  return (
    <div className="top-0 bg-[#F6F6F6] z-40 md:h-[70px] h-[56px] flex flex-col items-center justify-center w-full sticky">
      <MainNav2Logged />
    </div>
  )
}

export default HeaderLogged
