import React, { useState, useEffect, Fragment } from 'react'
import apis from 'apis'
import { createQuery } from 'utils/function'
import useInView from 'hooks/useInView'

const Inview: React.FC = () => {
  const [products, setProducts] = useState<any[]>([])
  const [pagination, setPagination] = useState({
    hasNextPage: true,
    page: 1,
    pageSize: 10,
  })
  const [isLoading, setIsLoading] = useState(false)
  const { isInView, ref } = useInView()

  const getProducts = async () => {
    if (isLoading || !pagination.hasNextPage) return
    setIsLoading(true)
    apis
      .get(
        'wl-products',
        createQuery({
          sort: ['id'],
          pagination: {
            page: pagination.page,
            pageSize: pagination.pageSize,
          },
        })
      )
      .then((res) => {
        setProducts((prev) => [...prev, ...res.data.data])
        setPagination({
          page: res.data.meta.pagination.page + 1,
          pageSize: res.data.meta.pagination.pageSize,
          hasNextPage:
            res.data.meta.pagination.pageCount > res.data.meta.pagination.page,
        })
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (isInView) {
      getProducts()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView])

  return (
    <div>
      <div>product count: {products.length}</div>
      <div>current page: {pagination.page - 1}</div>
      <div>has next page: {pagination.hasNextPage.toString()}</div>
      <div>
        {products.map((product, idx) => (
          <Fragment key={product.id}>
            {products.length === idx + 1 && pagination.hasNextPage && (
              <div className="w-full h-1" ref={ref}></div>
            )}
            <div>
              <p className="h-96 bg-slate-400  text-white">
                product id: {product.id}, product name:{' '}
                {product.attributes.name}
              </p>
            </div>
          </Fragment>
        ))}
      </div>
      {isLoading && <p>Loading...</p>}
    </div>
  )
}

export default Inview
