/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react'
import HeaderLogged from '../components/Header/HeaderLogged'
import { useLocation } from 'react-router-dom'

const SiteHeader = () => {
    let pathname = useLocation().pathname

    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [pathname])

    const headerComponent = useMemo(() => {
        let HeadComponent = HeaderLogged

        switch (pathname) {
            case '/home-2':
                HeadComponent = HeaderLogged
                break
            case '/home-3':
                HeadComponent = HeaderLogged
                break

            default:
                break
        }

        return <HeadComponent />
    }, [pathname])

    return <>{headerComponent}</>
}
export default SiteHeader
